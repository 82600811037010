import React , { useState , useRef , useEffect } from "react";
import IndexStyle from './indexStyle';
import StylesGlobal from '../StylesGlobal';
import HeaderRate from '../sections/HeaderRate/HeaderRate';
import Map from '../sections/Map/Map';
import NotificationSystem from 'react-notification-system';
import { reactLocalStorage } from 'reactjs-localstorage';
import { isEmpty } from '../actions/functions';
import Login from '../sections/Login/Login';
import api from '../actions/api';
import SeoMeta from "../components/SeoMeta/SeoMeta"
import { useQueryParam, StringParam } from "use-query-params";
import AddRate from '../sections/AddRate/AddRate';
import Cookies from "../components/Cookies/Cookies";

const IndexPage = () => {

    const [tkn, setTkn] = useQueryParam("tkn", StringParam);
    const refNotify = useRef();
    const refContact = useRef();
    const refTop = useRef();

    const [showForm,setShowForm] = useState(0);
    const [userData,setUserData] = useState(null);
    const [userToken,setUserToken] = useState('');
    const [readUserDataStorage,setReadUserDataStorage] = useState(0);
    const [showLogin,setShowLogin] = useState(false);
    const [order,setOrder] = useState(null);

    useEffect(() => {
        if (tkn!=''){
            api("getInfoOrderByToken/"+tkn,"",{},r => {
                if (r.result){
                    setOrder(r.data.order);
                } else {
                    window.location.href="/";
                }
            });
        } else {
            window.location.href='/';
        }
    },[]);

    useEffect(() => {
        const tempUserData = reactLocalStorage.getObject('userData');
        if (!isEmpty(tempUserData)){
            setUserData(tempUserData);
        }
        const tempUserToken = reactLocalStorage.get('userToken');
        if (tempUserToken !== undefined){
            setUserToken(tempUserToken);
        }
    },[readUserDataStorage]);

    const userLogout = () => {
        api('logout',userToken,{},r => {
            reactLocalStorage.remove('userToken');
            reactLocalStorage.remove('userData');
            setUserData(null);
            setUserToken('');
        });
    }

    const Notify = (title ,message, level= 'success') => {
        refNotify.current.addNotification({
            title,
            message,
            level,
            position: 'tc'
        });
    }

    return (
        <>
            <SeoMeta />
            <StylesGlobal />
            <NotificationSystem ref={refNotify} />

            <IndexStyle id="wrapper">

                <HeaderRate userData={userData} setShowLogin={setShowLogin} userLogout={userLogout} refTop={refTop} />
                {order!==null && <AddRate order={order} Notify={Notify} />}
                <Map refContact={refContact} />

            </IndexStyle>
            {showLogin &&
                <Login setShowLogin={setShowLogin} setReadUserDataStorage={setReadUserDataStorage} Notify={Notify} />
            }
            <Cookies />
        </>
    )
}

export default IndexPage
