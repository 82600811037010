import styled from 'styled-components';
import { viewMax , viewHd , viewSd , viewMobile , viewHdSmall } from '../../actions/variables';

const AddRateStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    .contentWidth{
        h3.header{
            text-align: center;
            color: grey;
            padding: 20px;
            text-transform: uppercase;
        }
        .box{
            padding: 20px;
            border: 1px solid #b9b9b9;
            margin: 0px 20px 20px 20px;
            transition: all 0.3s;
            position: relative;
            .morda{
                position: absolute;
                top: 40px;
                right: 40px;
                text-align: right;
                img{
                    width: 28px;
                    height: 29px;
                }
                .label{
                    display: block;
                    font-size: 30px;
                }
                &.v1 .label{ color: red; }
                &.v2 .label{ color: #f96262; }
                &.v3 .label{ color: #f9b56c; }
                &.v4 .label{ color: #558dd4; }
                &.v5 .label{ color: green; }
            }
            label{
                display: inline-block;
                color: grey;
                font-size: 18px;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px dashed grey;
                transition: all 0.3s;
                span{
                    display: block;
                    font-size: 12px;
                    color: #bfbfbf;
                    transition: all 0.3s;
                }
            }
            .input{
                input , textarea{
                    display: block;
                    background-color: #efefef;
                    font-size: 18px;
                    color: grey;
                    border: 1px solid #d4d4d4;
                    padding: 15px 20px;
                    width: 100%;
                    outline: 0px;
                    transition: all 0.3s;
                }
                textarea{
                    min-height: 200px;
                    max-height: 200px;
                }
            }
            &.focused{
                background-color: #d4d4d4;
                label{
                    color: black;
                    span{
                        color: grey;
                    }
                }
                .input{
                    input{
                        border-color: grey;
                        background-color: white;
                    }
                }
            }
            ul{
                display: block;
                list-style: none;
                margin: 0px;
                padding: 0px;
                li{
                    display: inline-block;
                    padding: 0px;
                    margin: 0px;
                    position: relative;
                    cursor: pointer;
                    &:before{
                        content: '';
                        display: block;
                        width: 100%;
                        height: 100%;
                        left: 0px;
                        top: 0px;
                        /* background-color: red; */
                        position: absolute;
                    }
                    svg{
                        cursor: pointer;
                        width: 60px;
                        height: 60px;
                        path{
                            fill: #b9b9b9;
                        }
                    }
                    &.selected svg path{
                        fill: #2aa9e0;
                    }
                }
            }
        }
        .button{
            text-align: center;
            padding: 20px;
            height: 80px;
            button{
                min-width: 250px;
                padding: 20px 30px;
                font-size: 33px;
                text-transform: uppercase;
                outline: 0px;
                background-color: #efefef;
                font-size: 18px;
                color: grey;
                border: 1px solid #d4d4d4;
                cursor: pointer;
                transition: all 0.3s;
                &:hover{
                    color: white;
                    border-color: black;
                    background-color: black;
                }
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .contentWidth{
            .box{
                .morda{
                    img{
                        width: 21px;
                        height: 20px;
                    }
                    .label{
                        display: block;
                        font-size: 24px;
                    }
                }
                ul li svg{
                    width: 40px;
                    height: 40px;
                }
            }
            
        }
    }
    @media (max-width: ${viewMobile}px){
        .contentWidth{
            .box{
                .morda{
                    display: none !important;
                }
                ul li svg{
                    width: 36px;
                    height: 36px;
                }
            }
            
        }
    }
`;	

export default AddRateStyle;