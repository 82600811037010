import React , { useState, useEffect, useContext } from 'react';
import api from '../../actions/api';
import AddRateStyle from './AddRateStyle';
import { TiStarOutline as StarDefault, TiStarFullOutline as StarFill } from 'react-icons/ti';

import smile1 from '../../assets/images/smiles/1.png';
// import smile2 from '../../assets/images/smiles/2.png';
import smile3 from '../../assets/images/smiles/3.png';
import smile4 from '../../assets/images/smiles/4.png';
import smile5 from '../../assets/images/smiles/5.png';
import smile6 from '../../assets/images/smiles/6.png';
// import smile7 from '../../assets/images/smiles/7.png';

const AddRate = ({ order , Notify }) => {

    const [vHover,setVHover] = useState(0);
    const [v,setV] = useState(0);
    const [stars] = useState([
        { value: 1, label: 'źle' , img: smile1 },
        { value: 2, label: 'słabo' , img: smile3 },
        { value: 3, label: 'dobrze' , img: smile4 },
        { value: 4, label: 'bardzo dobrze' , img: smile5 },
        { value: 5, label: 'rewelacja' , img: smile6 },
    ]);
    const [nick,setNick] = useState('');
    const [content,setContent] = useState('');
    const [focusNick,setFocusNick] = useState(false);
    const [focusContent,setFocusContent] = useState(false);
    const [loading,setLoading] = useState(false);
    const [success,setSuccess] = useState(false);

    useEffect(() => {
        setNick(order.name)
    },[]);

    const setHover = obj => {
        if (!v) setVHover(obj.value);
    }

    const setMouseout = () => {
        if (!v){
            setVHover(0);
        }
    }

    const setClick = obj => {
        if (v!=obj.value) setV(obj.value);
        else setV(0);
    }

    const showFace = index => {
        const obj = stars.find(obj => obj.value === index)
        return (
            <div className={"morda v"+obj.value}>
                <img src={obj.img} />
                <div className="label">{obj.label}</div>
            </div>
        )
    }

    const handleSendButton = () => {
        if (!v){
            Notify('Komentarz do transakcji','Nie określiłeś ocenę transakcji, wystarczy  że klikniesz na gwiazdkę.','error');
        } else if (nick.length<3){
            Notify('Komentarz do transakcji','Aby dodać Twoją ocenę musisz podać swój Nick.','error');
        } else {
            setLoading(true);
            api('commentForm/'+order.token,'',{ name: nick, content, rate: v },r => {
                setLoading(false);
                if (r.result){
                    Notify('Komentarz do transakcji','Formularz z oceną został wysłany, dziękujemy za udział w naszej ankiecie.');
                    setSuccess(true);
                    setTimeout(() => {
                        window.location="/";
                    },8000);
                } else {
                    Notify('Komentarz do transakcji',r.comm,'error');
                }
            });
        }
    }

    return (
        <AddRateStyle>
            <div className="contentWidth">
                {!success && 
                    <>
                        <h3 className="header">Witaj , dziękujemy za transakcje w naszym sklepie. Zapraszamy do skomentowania i oceny transakcji.</h3>

                        <div className={"box"+(loading ? ' loadingLayer' : '')} onMouseOut={() => setMouseout()}>
                            <label>Ocena transakcji:</label>
                            <ul className="stars">
                                {stars.map(obj => (
                                    <li 
                                        key={`li_star_${obj.value}`} 
                                        className={"star-"+obj.value+(v && v>=obj.value ? ' selected' : '')} 
                                        onMouseOver={() => setHover(obj)}
                                        onClick={() => setClick(obj)}
                                    >
                                            {((!v && vHover>=obj.value) || (v && v>=obj.value)) ? <StarFill /> : <StarDefault />}
                                    </li>
                                ))}
                            </ul>
                            {v>0 && showFace(v)}
                            {(!v && vHover>0) && showFace(vHover)}
                        </div>
                        <div className={"box"+(focusNick ? ' focused' : '')+(loading ? ' loadingLayer' : '')}>
                            <label>Twój nick</label>
                            <div className="input">
                                <input 
                                    type="text" 
                                    value={nick} 
                                    onChange={ev => setNick(ev.target.value)}
                                    onFocus={() => setFocusNick(true)} 
                                    onBlur={() => setFocusNick(false)} 
                                />
                            </div>
                        </div>
                        <div className={"box"+(focusContent ? ' focused' : '')+(loading ? ' loadingLayer' : '')}>
                            <label>Komentarz <span>Opcjonalny</span></label>
                            <div className="input">
                                <textarea 
                                    type="text" 
                                    value={content} 
                                    onChange={ev => setContent(ev.target.value)}
                                    onFocus={() => setFocusContent(true)} 
                                    onBlur={() => setFocusContent(false)} 
                                />
                            </div>
                        </div>
                        <div className="button">
                            {!loading && <button onClick={handleSendButton}>Wyślij</button>}
                        </div>
                    </>
                }

            </div>
        </AddRateStyle>
    );
}
export default AddRate;